import React from "react";
import { NavLink } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import PropTypes from "prop-types";

import Logo from "../assets/images/logo-inline-no-bg.png";

const Links = [
  { name: "About", url: "/about" },
  { name: "Services", url: "/services" },
  { name: "Sectors", url: "/sectors" },
  { name: "Contact us", url: "/contact-us" },
];

const NavItem = ({ url, name, onClick }) => (
  <Button
    color="inherit"
    component={NavLink}
    to={url}
    onClick={onClick}
    sx={{
      textTransform: "none",
      fontSize: 16,
      letterSpacing: 0.5,
    }}
  >
    {name}
  </Button>
);

NavItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Header() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {Links.map((link) => (
        <MenuItem
          key={link.url}
          component={NavLink}
          to={link.url}
          onClick={handleMobileMenuClose}
        >
          {link.name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl" sx={{ backgroundColor: "#001b35" }}>
        <Toolbar>
          {/* Logo (CompanyName) on the left for all screen sizes */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
              justifyContent: "flex-start",
            }}
          >
            <Button color="inherit" component={NavLink} to="/">
              <img src={Logo} alt="logo" loading="lazy" height={50} />
            </Button>
          </Typography>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {Links.map((link) => (
              <NavItem key={link.url} url={link.url} name={link.name} />
            ))}
          </Box>

          {/* Mobile Burger Menu on the right */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      {renderMobileMenu}
    </AppBar>
  );
}

export default Header;
