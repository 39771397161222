import React from "react";
import { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";

import BackgroundIllustration from "../assets/images/power-plants-night.jpg";

import ContentSection from "../components/ContentSection";
import CompactCard from "../components/CompactCard";
import HeroSection from "../components/HeroSection";
import LinkButton from "../components/ui/LinkButton";

import { SectorsData, ServicesData } from "./constants";
import ServiceExpandableCard from "../components/ServiceExpandableCard";

function SectorsPage() {
  return (
    <Fragment>
      <HeroSection
        heading="Sectors We Serve"
        subheading="From energy to emerging markets, we serve a diverse range of sectors. Our expertise enables us to provide tailored solutions to clients operating in fast-paced and dynamic industries."
        backgroundImage={BackgroundIllustration}
      />
      <ContentSection>
        <Typography variant="subtitle1" marginTop={2}>
          Explore the sectors where we bring value and impact
        </Typography>
        <Grid container spacing={4} marginBlockStart={2}>
          {SectorsData.map((service, index) => (
            <Grid
              item
              key={index}
              xs={12} // 1 column on mobile
              sm={6} // 2 columns on small screens
              md={3} // 3 columns on medium and larger screens
            >
              <CompactCard icon={service.icon} title={service.heading} />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 6,
            gap: 1,
          }}
        >
          <LinkButton to="/contact-us" size="large">
            Contact Us
          </LinkButton>
        </Box>
      </ContentSection>
      <ContentSection>
        <Typography variant="subtitle1" marginTop={2}>
          Explore the wide range of services we offer
        </Typography>
        <Grid container spacing={4} marginBlockStart={2}>
          {ServicesData.map((service, index) => {
            return (
              <Grid
                item
                key={index}
                xs={12} // 1 column on mobile
                lg={6} // 2 columns on medium and larger screens
              >
                <ServiceExpandableCard service={service} />
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 6,
            gap: 1,
          }}
        >
          <LinkButton to="/contact-us" size="large">
            Contact Us
          </LinkButton>
        </Box>
      </ContentSection>
    </Fragment>
  );
}

export default SectorsPage;
