import {
  AutoGraphOutlined,
  CalculateOutlined,
  QueryStatsOutlined,
  FormatListNumberedOutlined,
  LocalShippingOutlined,
  InsightsOutlined,
  GavelOutlined,
  BusinessCenterOutlined,
  AccountBalanceOutlined,
  AllInclusiveOutlined,
  AccountTreeOutlined,
  PaymentsOutlined,
  EnergySavingsLeafOutlined,
  OilBarrelOutlined,
  AgricultureOutlined,
  NatureOutlined,
  ComputerOutlined,
  PrecisionManufacturingOutlined,
} from "@mui/icons-material";

import PropTypes from "prop-types";

import DevelopmentOfStrategicPlansImage from "../assets/images/service_images/development-of-strategic-plans-lg.jpg";
import FinancialModelingImage from "../assets/images/service_images/financial-modeling-lg.jpg";
import MarketAnalysisImage from "../assets/images/service_images/market-analysis-lg.jpg";
import TechnologicalAssessmentImage from "../assets/images/service_images/technological-assessment-lg.jpg";
import BusinessPlanDevelopmentImage from "../assets/images/service_images/business-plan-development-lg.jpg";
import InvestmentAttractionConsultingImage from "../assets/images/service_images/investment-attraction-consulting-lg.jpg";
import SupplyChainAndLogisticsAnalysisImage from "../assets/images/service_images/supply-chain-and-logistics-analysis-lg.jpg";
import MarketResearchImage from "../assets/images/service_images/market-research-lg.jpg";
import SustainableDevelopmentStrategyImage from "../assets/images/service_images/sustainable-development-strategy-lg.jpg";
import LegalAdviceEnergyAndEcologyImage from "../assets/images/service_images/legal-advice-energy-and-ecology-lg.jpg";
import BusinessDiversificationStrategiesImage from "../assets/images/service_images/business-diversification-strategies-lg.jpg";
import InternationalTaxationAndTaxPlanningImage from "../assets/images/service_images/international-taxation-and-tax-planning-lg.jpg";

export const ServicesData = [
  {
    heading: "Development of Strategic Plans",
    slug: "development-of-strategic-plans",
    shortDescription:
      "We help you identify optimal go-to-market and commercialization strategies, tailored to your products and technologies, ensuring smooth market entry and sustainable growth.",
    longDescription:
      "We specialize in creating customized strategic plans that ensure the successful market entry and commercialization of your projects. Our strategies are tailored to your products and technologies, focusing on identifying optimal go-to-market approaches and ensuring sustainable growth. Our process includes in-depth market analysis, segmentation, and identifying strategic partners to facilitate long-term partnerships.",
    descriptionItems: [
      {
        boldTitle: "In-depth market research:",
        title:
          "We provide comprehensive research across diverse regions to identify market opportunities and emerging trends that align with your business goals.",
      },
      {
        boldTitle: "Market segmentation and client identification:",
        title:
          "Through detailed segmentation analysis, we pinpoint high-potential clients to ensure your product reaches its target audience effectively.",
      },
      {
        boldTitle: "Partnership development:",
        title:
          "We help form strategic partnerships critical for your market success, facilitating negotiations with key clients and partners.",
      },
      {
        boldTitle: "Technical, economic, and legal advice:",
        title:
          "We offer expert guidance on existing sites across several countries, ensuring a smooth market entry process that meets all regulatory and operational standards.",
      },
      {
        boldTitle: "Pilot design evaluation:",
        title:
          "We provide technical assessments of pilot designs at existing facilities, ensuring your modules align with operational needs and local regulations.",
      },
    ],
    icon: AutoGraphOutlined,
    image: DevelopmentOfStrategicPlansImage,
  },
  {
    heading: "Financial Modeling",
    slug: "financial-modeling",
    shortDescription:
      "Our team develops precise and detailed financial models to assess the profitability, scalability, and sustainability of your energy projects, helping you make informed investment decisions.",
    longDescription:
      "Our team develops precise and detailed financial models designed to assess the profitability, scalability, and sustainability of your energy projects. Our financial modeling services include in-depth cost assessments, risk analysis, and comprehensive forecasting that help you make informed investment decisions. Each model is customized to your specific project needs, ensuring the long-term financial health and scalability of your venture.",
    descriptionItems: [
      {
        boldTitle: "Detailed financial models:",
        title: "We create bespoke financial models that outline profitability forecasts, operational costs, and scalability potential.",
      },
      {
        boldTitle: "Risk analysis:",
        title: "Our financial models incorporate detailed risk assessments, allowing you to identify and mitigate potential challenges early on."
      },
      {
        boldTitle: "On-site evaluations:",
        title: " We conduct comprehensive site visits to tailor financial models to the specific circumstances of your project, ensuring accurate financial planning."
      },
      {
        boldTitle: "Investment readiness:",
        title: "Our models are designed to make your project attractive to investors, providing clear financial data that showcases long-term viability and profitability."
      },
    ],
    icon: CalculateOutlined,
    image: FinancialModelingImage,
  },
  {
    heading: "Market & Competitive Environment Analysis",
    slug: "market-analysis",
    shortDescription:
      "We provide in-depth market and competitive analysis, empowering you to make informed decisions based on trends, competitors, and market opportunities.",
    longDescription:
      "We provide in-depth market and competitive analysis services, empowering your business to make informed decisions based on trends, competitors, and market opportunities. By conducting detailed research, we ensure that you stay ahead of competitors and capitalize on emerging opportunities.",
    descriptionItems: [
      {
        boldTitle: "Extensive market research:",
        title: "We conduct comprehensive research into market trends, competitor activity, and consumer behavior, giving you a complete picture of the current market landscape.",
      },
      {
        boldTitle: "Competitive benchmarking:",
        title: "We evaluate your competitors’ strengths and weaknesses, identifying areas where your company can gain a competitive edge.",
      },
      {
        boldTitle: "Opportunity identification:",
        title: "Our analysis helps you pinpoint new market opportunities, allowing you to capitalize on untapped areas of growth.",
      },
      {
        boldTitle: "Strategic insights:",
        title: "We offer actionable insights based on our findings, allowing you to refine your strategy and make data-driven decisions that boost your market position.",
      },
    ],
    icon: AccountTreeOutlined,
    image: MarketAnalysisImage,
  },
  {
    heading: "Technological Audit & Viability Assessment",
    slug: "technological-assessment",
    shortDescription:
      "We assess the readiness of your technologies for commercialization, analyzing potential risks and ensuring their operational viability in real-world market conditions.",
    longDescription:
      "Our technological audit and viability assessment services ensure that your technologies are fully prepared for commercialization. We analyze the operational viability of your solutions in real-world conditions, identify risks, and provide recommendations to optimize performance. This comprehensive evaluation ensures that your product is market-ready and capable of meeting client expectations.",
    descriptionItems: [
      {
        boldTitle: "Technology readiness assessment:",
        title: "We evaluate your technology’s development stage and its readiness for commercialization, ensuring it is prepared for market launch.",
      },
      {
        boldTitle: "Operational viability testing:",
        title: "Our team conducts thorough tests under real-world conditions to ensure your product meets the necessary performance standards.",
      },
      {
        boldTitle: "Risk mitigation:",
        title: "We identify potential technological risks and develop strategies to mitigate them, ensuring reliable product performance.",
      },
      {
        boldTitle: "Facility assessments:",
        title: "We assess the feasibility of integrating your technology into existing facilities, ensuring compatibility and seamless operation.",
      },
    ],
    icon: QueryStatsOutlined,
    image: TechnologicalAssessmentImage,
  },
  {
    heading: "Business Plan Development",
    slug: "business-plan-development",
    shortDescription:
      "We craft comprehensive business plans that incorporate market research, marketing strategies, and financial forecasts, positioning your venture for success.",
    longDescription:
      "We craft comprehensive business plans that incorporate market research, marketing strategies, and financial forecasts, positioning your venture for success. Our business plans are designed to guide your company through successful market entry, scale-up, and long-term growth.",
    descriptionItems: [
      {
        boldTitle: "Tailored business plans:",
        title: "We develop customized business plans that align with your market entry strategy and long-term business goals.",
      },
      {
        boldTitle: "Market research integration:",
        title: "Each business plan incorporates detailed market research and competitive analysis, ensuring your strategy is informed by real-world data.",
      },
      {
        boldTitle: "Financial forecasting:",
        title: "Our plans include detailed financial forecasts, providing insight into revenue projections, operating expenses, and profitability potential.",
      },
      {
        boldTitle: "Operational roadmap:",
        title: "We outline clear operational steps, including resource management, supply chain planning, and logistics, ensuring your business is set up for success.",
      },
      {
        boldTitle: "Investor engagement:",
        title: "Our business plans are designed to attract investors, showcasing your company’s potential and highlighting key opportunities for growth.",
      },
    ],
    icon: FormatListNumberedOutlined,
    image: BusinessPlanDevelopmentImage,
  },
  {
    heading: "Investment Attraction Consulting",
    slug: "investment-attraction-consulting",
    shortDescription:
      "We help you prepare strategies and materials for attracting investors and funding, ensuring that your business is positioned to secure the capital needed for growth.",
    longDescription:
      "We help you attract the right investors and secure the funding needed for growth. Our investment attraction services include creating compelling investment materials, identifying potential investors, and providing support throughout the negotiation process. By presenting clear value propositions, we ensure your project attracts the right financial backers.",
    descriptionItems: [
      {
        boldTitle: "Targeting investors:",
        title: "We identify and approach investors whose interests align with your project, ensuring you secure the right financial partnerships.",
      },
      {
        boldTitle: "Compelling investment materials:",
        title: "We create investor-ready materials, including business plans, pitch decks, and financial models, that clearly communicate your project’s value and profitability.",
      },
      {
        boldTitle: "Negotiation support:",
        title: "Our team assists in investor negotiations, ensuring that your agreements align with your long-term business goals.",
      },
      {
        boldTitle: "Due diligence:",
        title: "We provide support during the due diligence process, ensuring all necessary information is presented clearly to secure investor confidence.",
      },
    ],
    icon: PaymentsOutlined,
    image: InvestmentAttractionConsultingImage,
  },
  {
    heading: "Supply Chain & Logistics Analysis",
    slug: "supply-chain-and-logistics-analysis",
    shortDescription:
      "We optimize your supply chain and logistics, identifying opportunities to reduce costs and enhance efficiency in your operations.",
    longDescription:
      "We optimize your supply chain and logistics operations, identifying opportunities to reduce costs and improve efficiency. Our analysis helps streamline processes, ensuring your operations can scale efficiently while maintaining profitability.",
    descriptionItems: [
      {
        boldTitle: "Supply chain optimization:",
        title: "We evaluate your current supply chain to identify inefficiencies and opportunities for cost reduction and process improvement.",
      },
      {
        boldTitle: "Cost reduction strategies:",
        title: "We develop strategies to reduce operational costs and improve overall profitability through efficient logistics management.",
      },
      {
        boldTitle: "Logistics streamlining:",
        title: "Our team assesses your logistics operations, offering solutions to reduce lead times, improve delivery performance, and enhance customer satisfaction.",
      },
      {
        boldTitle: "Supplier management:",
        title: "We help you build stronger relationships with suppliers, ensuring reliable sourcing and procurement processes.",
      },
    ],
    icon: LocalShippingOutlined,
    image: SupplyChainAndLogisticsAnalysisImage,
  },
  {
    heading: "Market Research & Analysis",
    slug: "market-research",
    shortDescription:
      "Our team conducts thorough market research, studying trends, consumer behavior, and the competitive landscape to give you a competitive edge.",
    longDescription:
      "Our comprehensive market research services provide you with in-depth insights into current market trends, consumer behavior, and competitive dynamics. By identifying key opportunities and challenges, we help you refine your strategy and stay ahead of the competition.",
    descriptionItems: [
      {
        boldTitle: "Consumer behavior analysis:",
        title: "We study the preferences and behaviors of your target audience, allowing you to tailor your products and services accordingly.",
      },
      {
        boldTitle: "Trend forecasting:",
        title: "We forecast market trends, ensuring your company remains ahead of industry shifts and can capitalize on new opportunities.",
      },
      {
        boldTitle: "Competitive analysis:",
        title: "We provide detailed insights into your competitors’ strategies, enabling you to refine your approach and gain a competitive edge.",
      },
      {
        boldTitle: "Market opportunity identification:",
        title: "We help you identify and prioritize high-potential market opportunities that align with your business objectives.",
      },
    ],
    icon: InsightsOutlined,
    image: MarketResearchImage,
  },
  {
    heading: "Sustainable Development Strategy",
    slug: "sustainable-development-strategy",
    shortDescription:
      "We provide consulting on implementing environmentally responsible and socially sustainable practices, ensuring your business remains ethical and forward-thinking.",
    longDescription:
      "We provide consulting on implementing environmentally responsible and socially sustainable practices, ensuring your business remains ethical and forward-thinking. Our sustainable development strategies align your business with global environmental standards while maintaining profitability and growth.",
    descriptionItems: [
      {
        boldTitle: "Sustainable business models:",
        title: "We help you develop sustainable business models that minimize environmental impact while maximizing efficiency and profitability.",
      },
      {
        boldTitle: "Regulatory compliance:",
        title: "Our team ensures your company complies with environmental regulations and sustainability standards, both locally and globally.",
      },
      {
        boldTitle: "Energy efficiency consulting:",
        title: "We advise on integrating energy-efficient solutions into your operations, helping reduce costs and minimize your carbon footprint.",
      },
      {
        boldTitle: "Corporate social responsibility (CSR):",
        title: "We provide strategies for integrating socially responsible practices into your business, ensuring you meet ethical and social expectations.",
      },
    ],
    icon: AllInclusiveOutlined,
    image: SustainableDevelopmentStrategyImage,
  },
  {
    heading: "Legal Advice in Energy & Ecology",
    slug: "legal-advice-energy-and-ecology",
    shortDescription:
      "Our legal consulting services focus on licensing, regulatory compliance, and navigating the complexities of the energy and ecological industries.",
    longDescription:
      "Our legal consulting services focus on licensing, regulatory compliance, and navigating the complexities of the energy and ecological industries. We ensure that your projects comply with all legal requirements and are set up for long-term success.",
    descriptionItems: [
      {
        boldTitle: "Licensing and permitting:",
        title: "We assist you in obtaining the necessary licenses and permits for your projects, ensuring compliance with local and international regulations.",
      },
      {
        boldTitle: "Environmental law compliance:",
        title: "Our team ensures that your operations meet environmental standards and regulations, minimizing legal risks.",
      },
      {
        boldTitle: "Risk management:",
        title: "We help you identify and mitigate legal risks associated with your energy and ecological projects, protecting your business from potential regulatory challenges.",
      },
      {
        boldTitle: "Regulatory guidance:",
        title: "We provide expert guidance on navigating the complex legal landscapes of the energy and environmental sectors, ensuring your projects are fully compliant.",
      },
    ],
    icon: GavelOutlined,
    image: LegalAdviceEnergyAndEcologyImage,
  },
  {
    heading: "Business Diversification Strategies",
    slug: "business-diversification-strategies",
    shortDescription:
      "We help you develop and execute diversification strategies that allow you to expand your business and mitigate risks across different markets and sectors.",
    longDescription:
      "We help businesses develop and execute diversification strategies, allowing you to expand into new markets, products, and services. Our strategies are designed to mitigate risks and increase profitability, ensuring your business remains agile in a changing market landscape.",
    descriptionItems: [
      {
        boldTitle: "Market diversification:",
        title: "We help you identify new market opportunities and develop strategies to successfully enter these markets.",
      },
      {
        boldTitle: "Risk mitigation:",
        title: "Our diversification strategies are designed to spread risk across different markets and products, protecting your business from market fluctuations.",
      },
      {
        boldTitle: "Growth planning:",
        title: "We create long-term growth plans that outline the steps necessary to expand your product offerings and market presence.",
      },
      {
        boldTitle: "Strategic partnerships:",
        title: "We help you form key partnerships that support your diversification efforts and enhance your business’s ability to expand.",
      },
    ],
    icon: BusinessCenterOutlined,
    image: BusinessDiversificationStrategiesImage,
  },
  {
    heading: "International Taxation & Tax Planning",
    slug: "international-taxation-and-tax-planning",
    shortDescription:
      "We provide expert guidance on optimizing tax liabilities when entering international markets, ensuring compliance while maximizing profitability.",
    longDescription:
      "Our international taxation and tax planning services help you optimize your tax liabilities when entering global markets. We provide expert guidance on international tax regulations, ensuring that your business remains compliant while maximizing profitability.",
    descriptionItems: [
      {
        boldTitle: "Tax liability reduction:",
        title: "We identify opportunities to minimize your tax liabilities, ensuring compliance while maximizing your bottom line.",
      },
      {
        boldTitle: "Cross-border tax compliance:",
        title: "Our team ensures that your business adheres to the tax regulations of the countries in which you operate.",
      },
    ],
    icon: AccountBalanceOutlined,
    image: InternationalTaxationAndTaxPlanningImage,
  },
];

export const SectorsData = [
  {
    heading: "Renewable Energy",
    icon: EnergySavingsLeafOutlined,
  },
  {
    heading: "Oil & Gas",
    icon: OilBarrelOutlined,
  },
  {
    heading: "Sustainable Agriculture",
    icon: AgricultureOutlined,
  },
  {
    heading: "Finance & Investment",
    icon: PaymentsOutlined,
  },
  {
    heading: "Environmental & Ecological Solutions",
    icon: NatureOutlined,
  },
  {
    heading: "Technology & IT Infrastructure",
    icon: ComputerOutlined,
  },
  {
    heading: "Industrial & Manufacturing",
    icon: PrecisionManufacturingOutlined,
  },
  {
    heading: "Logistics & Supply Chain Management",
    icon: LocalShippingOutlined,
  },
];

export const FormEndpoint = "https://formspree.io/f/mqakddgp";

export const ServicePropType = Object.freeze({
    heading: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string.isRequired,
    descriptionItems: PropTypes.arrayOf(PropTypes.shape({
      boldTitle: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
    icon: PropTypes.element.isRequired,
    image: PropTypes.string.isRequired,
});