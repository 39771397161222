import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";

import GlassBuildingWithReflection from "../assets/images/glass-building-reflection.jpg";
import OfficeSettingWithWindows from "../assets/images/glass-hq.jpg";
import BusinessStrategies from "../assets/images/service_images/business-diversification-strategies-lg.jpg";

import HeroSection from "../components/HeroSection";
import ContentSection from "../components/ContentSection";
import LinkButton from "../components/ui/LinkButton";
import ContentBackgroundedSection from "../components/ContentBackgroundedSection";
import { SectorsData, ServicesData } from "./constants";
import CompactCard from "../components/CompactCard";
import HorizontalScroll from "../components/HorizontalScroll";

function HomePage() {
  // const theme = useTheme();

  return (
    <Fragment>
      <HeroSection
        backgroundImage={GlassBuildingWithReflection}
        heading="Transforming Ideas into Actionable Strategies"
        subheading="Expert Consulting in Market Commercialization and Financial Modeling for Innovative Energy Solutions"
      />
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "48px auto",
            gap: 6,
          }}
        >
          <Typography
            color="#001b35"
            variant="h3"
            sx={{ flex: 1, flexShrink: 0 }}
          >
            We help you take the right steps toward the successful
            commercialization of your innovative energy solutions.
          </Typography>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35" variant="body1">
              With expertise in market entry strategies and precise financial
              modeling, we support your journey to sustainable growth and
              cost-effective operations. Whether you’re entering new markets or
              expanding existing ones, our tailored approach ensures your ideas
              come to life with impact and purpose.
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: 3,
                gap: 1,
              }}
            >
              <LinkButton to="/about" size="large">
                Get Started
              </LinkButton>
              <LinkButton to="/contact-us" size="large">
                Contact Us
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </ContentSection>
      <ContentBackgroundedSection backgroundImage={OfficeSettingWithWindows}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "0 auto",
            gap: 6,
            maxWidth: "1170px",
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="white" variant="h2" marginBlockStart={2}>
              About Us
            </Typography>
            <Typography color="white" variant="subtitle1" marginTop={3}>
              Who We Are
            </Typography>
          </Box>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="white"
              variant="body1"
              fontWeight={500}
              fontStyle={"italic"}
            >
              Our company specializes in developing strategic solutions for
              businesses seeking to enter Asian and European markets and in
              crafting financial models for cutting-edge energy projects. With a
              combination of deep market knowledge, economic expertise, and
              hands-on experience, we help clients achieve their unique business
              goals. We understand that no two projects are alike, which is why
              we provide tailored solutions, addressing the individual needs of
              each client to drive long-term success.
            </Typography>
            <Box marginTop={2}>
              <LinkButton color="tertiary" to="/services">
                Read more
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </ContentBackgroundedSection>
      <ContentSection>
        <Box marginTop={6} marginBottom={6}>
          <Typography align="center" variant="h2">
            Our Services
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            maxWidth="1000px"
            margin="24px auto"
          >
            Our comprehensive consulting services help you successfully
            commercialize your innovative solutions and evaluate their
            cost-effectiveness.
          </Typography>
          <HorizontalScroll
            items={ServicesData.map((sector) => (
              <CompactCard
                key={sector.heading}
                icon={sector.icon}
                title={sector.heading}
              />
            ))}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 4,
              gap: 1,
            }}
          >
            <LinkButton to="/services">Explore all services</LinkButton>
          </Box>
        </Box>
        <ContentBackgroundedSection backgroundImage={BusinessStrategies}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              margin: "0 auto",
              gap: 6,
              maxWidth: "1170px",
            }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="white" variant="h3">
                Ready to discuss your project?
              </Typography>
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography
                color="white"
                variant="body1"
                fontWeight={500}
                fontStyle={"italic"}
                marginBlockStart={1}
              >
                Contact us and we will be happy to help you achieve success.
              </Typography>
              <Box marginTop={2}>
                <LinkButton color="tertiary" to="/contact-us">
                  Get in touch
                </LinkButton>
              </Box>
            </Box>
          </Box>
        </ContentBackgroundedSection>
        <Box marginTop={10} marginBottom={6}>
          <Typography align="center" variant="h2">
            Sectors We Serve
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            maxWidth="1000px"
            margin="24px auto"
          >
            From energy to emerging markets, we serve a diverse range of
            sectors.
          </Typography>
          <HorizontalScroll
            items={SectorsData.map((sector) => (
              <CompactCard
                key={sector.heading}
                icon={sector.icon}
                title={sector.heading}
              />
            ))}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 4,
              gap: 1,
            }}
          >
            <LinkButton to="/sectors">Explore all sectors</LinkButton>
          </Box>
        </Box>
      </ContentSection>
    </Fragment>
  );
}

export default HomePage;
