import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const ContentSection = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        padding: 4,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          maxWidth: '1170px', // Custom max-widths for small and large screens
          margin: "0 auto", // Center the content
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

ContentSection.propTypes = {
  children: PropTypes.node,
};

export default ContentSection;
