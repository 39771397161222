import React from "react";
import { Box } from "@mui/material";

import PropTypes from "prop-types";

const ContentBackgroundedSection = ({ backgroundImage, children, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        marginLeft: {xs: 0, lg: 4},
        marginRight: {xs: 0, lg: 4},
        padding: 4,
        paddingTop: 24,
        paddingBottom: 8,
        ...props.sx
      }}
    >
      {/* Background Image with Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 1,
          borderRadius: "32px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 27, 53, 0.6)", // Dark blue color with 0.4 opacity
            borderRadius: "32px"
          }}
        />
      </Box>

      {/* Content */}
      <Box
        sx={{
          zIndex: 2, // Ensures content is above the overlay
          color: "#ffffff",
          width: "1170px",
          maxWidth: "1170px",
          margin: "0 auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

ContentBackgroundedSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentBackgroundedSection;
