import React from "react";
import { Box, Typography } from "@mui/material";

import OfficeSettingWithWindows from "../assets/images/glass-hq.jpg";
import HeroSection from "../components/HeroSection";
import LinkButton from "../components/ui/LinkButton";
import ContentSection from "../components/ContentSection";

import Svg from "../assets/svg/arabic-pattern.svg";

function AboutPage() {
  return (
    <>
      <HeroSection
        backgroundImage={OfficeSettingWithWindows}
        heading="About us"
        subheading="Who We Are"
      />
      <Box
        sx={{
          backgroundImage: `url(${Svg})`,
          backgroundSize: { xs: "100%", lg: "60%" },
          backgroundPosition: { xs: "200px 40%", lg: "bottom -400px left -200px" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <ContentSection>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              margin: "48px auto",
              gap: 6,
            }}
          >
            <Typography
              color="#001b35"
              variant="h6"
              fontWeight={400}
              sx={{ flex: 1, flexShrink: 0 }}
            >
              ALAN FZE is a leading consulting company in Dubai that assists
              clients with expert Consulting in Market Commercialization and
              Financial Modeling for Innovative Energy Solutions- research,
              feasibility studies and business setups . We offer customized
              solutions and keeping very high quality standards.
            </Typography>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="body1">
                <Typography>
                  Our company specializes in developing strategic solutions for
                  businesses seeking to enter Asian and European markets and in
                  crafting financial models for cutting-edge energy projects.
                  With a combination of deep market knowledge, economic
                  expertise, and hands-on experience, we help clients achieve
                  their unique business goals. We understand that no two
                  projects are alike, which is why we provide tailored
                  solutions, addressing the individual needs of each client to
                  drive long-term success.
                </Typography>
                <Typography variant="body1" marginTop={1}>
                  We understand the value of high-quality data and its impact on
                  commercial decision-making, our company can support in market
                  sizing and trend analysis, competitor assessment, and consumer
                  behavior research.
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  marginTop: 3,
                  gap: 1,
                }}
              >
                <LinkButton to="/services" size="large">
                  Explore services
                </LinkButton>
                <LinkButton to="/contact-us" size="large">
                  Contact Us
                </LinkButton>
              </Box>
            </Box>
          </Box>
        </ContentSection>
      </Box>
    </>
  );
}

export default AboutPage;
