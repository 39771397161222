import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import HeroSection from "../components/HeroSection";
import ContentSection from "../components/ContentSection";
import LinkButton from "../components/ui/LinkButton";

import { ServicesData } from "./constants";
import { lowercaseFirstLetter } from "../utils/utils";

const ServicePage = () => {
  const { slug } = useParams();
  const service = ServicesData.find((service) => service.slug === slug);

  if (!service) {
    return <h1>Service not found</h1>;
  }

  const { heading, longDescription, descriptionItems, image } = service;

  return (
    <>
      <HeroSection heading={heading} backgroundImage={image} />
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "48px auto",
            gap: 6,
          }}
        >
          <Typography
            color="#001b35"
            variant="h6"
            fontWeight={400}
            sx={{ flex: 1, flexShrink: 0 }}
          >
            {longDescription}
          </Typography>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35" variant="body1">
              {descriptionItems.map((item, index) => (
                <Typography marginBottom={1} key={index}>
                  <strong>{item.boldTitle}</strong>{" "}
                  {lowercaseFirstLetter(item.title)}
                </Typography>
              ))}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: 3,
                gap: 1,
              }}
            >
              <LinkButton to="/contact-us" size="large">
                Contact Us
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </ContentSection>
    </>
  );
};

export default ServicePage;
