import React from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";

import PropTypes from "prop-types";

function CompactCard({ action, icon, title, description, ...props }) {
  const IconComponent = icon;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%", // Make the card take full height of its parent
        width: "100%", // Ensure card takes full width of its parent
        ...props.sx,
        // background: 'linear-gradient(135deg, #e0f7fa, #b2ebf2)',
      }}
    >
      <CardContent>
        {IconComponent && (
          <IconComponent
            sx={{ fontSize: 32, color: "primary.dark", marginBottom: 2 }}
          />
        )}
        <Typography
          variant="subtitle2"
          component="div"
          gutterBottom={!!description}
        >
          {title}
        </Typography>
        {description && (
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        )}
      </CardContent>
      {action && <CardActions sx={{ padding: 2 }}> {action}</CardActions>}
    </Card>
  );
}

CompactCard.propTypes = {
  action: PropTypes.node,
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default CompactCard;
