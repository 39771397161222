import React from "react";
import { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";

import HeroSection from "../components/HeroSection";
import ContentSection from "../components/ContentSection";
import CompactCard from "../components/CompactCard";
import LinkButton from "../components/ui/LinkButton";

import { ServicesData } from "./constants";
import WorkingHandsExperts from "../assets/images/working-hands-experts.jpg";

function ServicesPage() {
  return (
    <Fragment>
      <HeroSection
        heading="Our Services"
        subheading="Our comprehensive consulting services help you successfully commercialize your innovative solutions and evaluate their cost-effectiveness. Our expertise spans across multiple areas to ensure your business thrives in competitive and dynamic markets."
        backgroundImage={WorkingHandsExperts}
      />
      <ContentSection>
        <Typography variant="subtitle1" marginTop={2}>
          Explore the wide range of services we offer
        </Typography>
        <Grid container spacing={4} marginBlockStart={2}>
          {ServicesData.map((serviceItem, index) => (
            <Grid
              item
              key={index}
              xs={12} // 1 column on mobile
              sm={6} // 2 columns on small screens
              md={4} // 3 columns on medium and larger screens
            >
              <CompactCard
                action={
                  <LinkButton
                  color="tertiary"
                    size="small"
                    to={`/services/${serviceItem.slug}`}
                  >
                    Read more
                  </LinkButton>
                }
                icon={serviceItem.icon}
                title={serviceItem.heading}
                description={<div>{serviceItem.shortDescription}</div>}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 6,
            gap: 1,
          }}
        >
          <LinkButton to="/sectors" size="large">
            Explore sectors
          </LinkButton>
          <LinkButton to="/contact-us" size="large">
            Contact Us
          </LinkButton>
        </Box>
      </ContentSection>
    </Fragment>
  );
}

export default ServicesPage;
