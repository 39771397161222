import React from "react";
import { Box, Link, Typography } from "@mui/material";
import {
  LocationOnOutlined,
  MailOutline,
  NumbersOutlined,
  PhoneInTalkTwoTone,
} from "@mui/icons-material";

import Logo from "../assets/images/logo-full-removebg-preview.png";

export default function Footer() {
  return (
    <Box color="white">
      <Box
        sx={{
          backgroundColor: "#001b35",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: 5,
          padding: { xs: 4, lg: 6 },
        }}
      >
        <img alt="logo" src={Logo} width={200} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
            flexGrow: 0,
          }}
        >
          <Typography sx={{ display: "flex", gap: 1 }}>
            <LocationOnOutlined /> ALAN FZE Office no-E2-110G-04, Hamriyah Free
            Zone Sharjah, United Arab Emirates
          </Typography>
          <Typography sx={{ display: "flex", gap: 1 }}>
            <NumbersOutlined /> Identification number: 14158
          </Typography>
          <Typography sx={{ display: "flex", gap: 1 }}>
            <PhoneInTalkTwoTone />
            <Link href="tel:+971527062372" sx={{ color: "white" }}>
              +971527062372
            </Link>
          </Typography>
          <Typography sx={{ display: "flex", gap: 1 }}>
            <MailOutline />
            <Link
              href="mailto:info@alanconsultinguae.com"
              sx={{ color: "white" }}
            >
              info@alanconsultinguae.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
