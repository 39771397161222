import React from 'react';
import { Box, Typography, useTheme, Container, Grid } from '@mui/material';

const PaletteDemoPage = () => {
  const theme = useTheme();
  const colors = [
    { name: 'Primary Light', color: theme.palette.primary.light },
    { name: 'Primary Main', color: theme.palette.primary.main },
    { name: 'Primary Dark', color: theme.palette.primary.dark },
    { name: 'Secondary Light', color: theme.palette.secondary.light },
    { name: 'Secondary Main', color: theme.palette.secondary.main },
    { name: 'Secondary Dark', color: theme.palette.secondary.dark },
    { name: 'Tertiary Light', color: theme.palette.tertiary.light },
    { name: 'Tertiary Main', color: theme.palette.tertiary.main },
    { name: 'Tertiary Dark', color: theme.palette.tertiary.dark },
    { name: 'Error Light', color: theme.palette.error.light },
    { name: 'Error Main', color: theme.palette.error.main },
    { name: 'Error Dark', color: theme.palette.error.dark },
    { name: 'Warning Light', color: theme.palette.warning.light },
    { name: 'Warning Main', color: theme.palette.warning.main },
    { name: 'Warning Dark', color: theme.palette.warning.dark },
    { name: 'Info Light', color: theme.palette.info.light },
    { name: 'Info Main', color: theme.palette.info.main },
    { name: 'Info Dark', color: theme.palette.info.dark },
    { name: 'Success Light', color: theme.palette.success.light },
    { name: 'Success Main', color: theme.palette.success.main },
    { name: 'Success Dark', color: theme.palette.success.dark },
    { name: 'Background Default', color: theme.palette.background.default },
    { name: 'Background Paper', color: theme.palette.background.paper },
    { name: 'Divider', color: theme.palette.divider },
  ];

  const textColors = [
    { name: 'Text Primary', color: theme.palette.text.primary },
    { name: 'Text Secondary', color: theme.palette.text.secondary },
    { name: 'Text Disabled', color: theme.palette.text.disabled },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Material-UI Theme Palette Demo
      </Typography>

      {/* Display Color Palette */}
      <Typography variant="h5" gutterBottom>
        Palette Colors
      </Typography>
      <Grid container spacing={2}>
        {colors.map((colorItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                backgroundColor: colorItem.color,
                color: theme.palette.getContrastText(colorItem.color),
                padding: '16px',
                borderRadius: '8px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">{colorItem.name}</Typography>
              <Typography variant="body1">{colorItem.color}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Display Text Colors */}
      <Typography variant="h5" gutterBottom sx={{ marginTop: '40px' }}>
        Text Colors
      </Typography>
      <Grid container spacing={2}>
        {textColors.map((textColorItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: textColorItem.color,
                padding: '16px',
                borderRadius: '8px',
                textAlign: 'center',
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography variant="h6">{textColorItem.name}</Typography>
              <Typography variant="body1">{textColorItem.color}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PaletteDemoPage;
