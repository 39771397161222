import React from "react";
import { Box, Typography } from "@mui/material";

import PropTypes from "prop-types";

const HeroSection = ({ backgroundImage, heading, subheading = undefined }) => {
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "50dvh",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        padding: 4,
        paddingTop: { xs: 12, sm: 10, md: 10 },
        paddingBottom: { xs: 8, sm: 8, md: 8 },
      }}
    >
      {/* Background Image with Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 1,
          borderRadius: "0 0 32px 32px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 27, 53, 0.6)", // Dark blue color with 0.4 opacity
            borderRadius: "0 0 32px 32px",
          }}
        />
      </Box>

      {/* Content */}
      <Box
        sx={{
          zIndex: 2, // Ensures content is above the overlay
          color: "#ffffff",
          width: "1170px",
          maxWidth: "1170px",
          margin: "0 auto",
        }}
      >
        <Box maxWidth="600px">
          <Typography variant="h1" component="h1" fontWeight={700} gutterBottom>
            {heading}
          </Typography>
          <Typography variant="subtitle1" component="h2" marginTop={3}>
            {subheading}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

HeroSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

export default HeroSection;
