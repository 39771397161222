import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AboutPage from "./pages/AboutPage.jsx";
import ContactPage from "./pages/ContactPage.jsx";
import ServicesPage from "./pages/ServicesPage.jsx";
import SectorsPage from "./pages/SectorsPage.jsx";
import ServicePage from "./pages/ServicePage.jsx";
import HomePage from "./pages/HomePage.jsx";

import PaletteDemoPage from "./pages/PaletteDemoPage.jsx";

import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import ScrollToTop from "./components/ui/ScrollToTop.jsx";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/palette" element={<PaletteDemoPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/services/:slug" element={<ServicePage />} />
        <Route path="/sectors" element={<SectorsPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
