import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { lowercaseFirstLetter } from "../utils/utils";

import { ServicePropType } from "../pages/constants";

function ServiceExpandableCard({ service }) {
  const { image, heading, longDescription, descriptionItems } = service;

  const [showMore, setShowMore] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: "100%", // Make the card take full height of its parent
        width: "100%", // Ensure card takes full width of its parent
        mb: 2,
      }}
    >
      <CardMedia component="img" height="250" image={image} alt="" />
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs gap={2}>
            <Grid container alignItems="center" justifyContent="start" gap={2}>
              <Typography variant="subtitle2">{heading}</Typography>
              <Typography variant="body2" sx={{ width: "100%" }}>
                {longDescription}
              </Typography>
              {showMore && (
                <Typography color="#001b35">
                  {descriptionItems.map((item, index) => (
                    <Typography marginBottom={1} key={index} variant="body2">
                      <strong>{item.boldTitle}</strong>{" "}
                      {lowercaseFirstLetter(item.title)}
                    </Typography>
                  ))}
                </Typography>
              )}
              <Button
                variant="contained"
                color="tertiary"
                disableElevation
                size="small"
                sx={{
                  fontWeight: 700,
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ServiceExpandableCard.propTypes = {
  service: ServicePropType,
};

export default ServiceExpandableCard;
