import PropTypes from "prop-types";

import React, { useRef, useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";


const HorizontalScroll = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if mobile screen size
  const scrollRef = useRef(null);
  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  const duplicatedItems = [...items, ...items]; // Duplicate items for infinite scrolling

  const updateGradients = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      setShowLeftGradient(scrollElement.scrollLeft > 0);
      setShowRightGradient(
        scrollElement.scrollLeft + scrollElement.clientWidth <
          scrollElement.scrollWidth
      );
    }
  };

  const checkVisibility = () => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return false;

    const rect = scrollElement.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  const handleAutoScroll = () => {
    const scrollElement = scrollRef.current;
    if (!scrollElement || !isAutoScrolling) return;

    const scrollStep = 1; // Speed of scrolling
    scrollElement.scrollLeft += scrollStep;

    // Reset scroll to maintain the infinite loop
    if (scrollElement.scrollLeft >= scrollElement.scrollWidth / 2) {
      scrollElement.scrollLeft = 0;
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    updateGradients(); // Initialize gradient visibility

    const interval = setInterval(() => {
      if (checkVisibility()) {
        setIsAutoScrolling(true);
        handleAutoScroll();
      } else {
        setIsAutoScrolling(false);
      }
    }, 16); // Smooth scrolling at ~60fps

    if (scrollElement) {
      scrollElement.addEventListener("scroll", updateGradients);
      return () => {
        clearInterval(interval);
        scrollElement.removeEventListener("scroll", updateGradients);
      };
    }
  }, [isAutoScrolling]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden", // Hide content that overflows outside the container
      }}
    >
      {/* Left Gradient */}
      {showLeftGradient && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "10%",
            background: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
      )}

      {/* Right Gradient */}
      {showRightGradient && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "10%",
            background: "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
            pointerEvents: "none",
            zIndex: 1,
          }}
        />
      )}

      {/* Scrollable Content */}
      <Box
        sx={{
          display: "flex",
          scrollSnapType: "x mandatory",
          overflowX: "auto", // Enable horizontal scrolling
          "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
        }}
        ref={scrollRef}
      >
        {duplicatedItems.map((item, index) => (
          <Box
            key={index}
            flexShrink={0}
            width={isMobile ? "calc(100% / 1.8)" : "calc(100% / 4.6)"} // 1.7 items on mobile, 4.6 items on desktop
            maxWidth="100%" // Ensure items don't exceed container width
            padding={1}
            boxSizing="border-box"
          >
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

HorizontalScroll.propTypes = {
  items: PropTypes.node,
};

export default HorizontalScroll;
