import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import Landscape from "../assets/images/dubai-landscape.jpg";
import ContentSection from "../components/ContentSection";
import {
  LocationOnOutlined,
  MailOutline,
  NumbersOutlined,
  PhoneInTalkTwoTone,
} from "@mui/icons-material";
import { FormEndpoint } from "./constants";

import Svg from "../assets/svg/email-mail.svg";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(FormEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({ name: "", email: "", message: "" });
      } else {
        setSuccess(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeroSection
        backgroundImage={Landscape}
        heading="Get in Touch"
        subheading="Ready to discuss your project? Contact us and we will be happy to help you achieve success."
      />
            <Box
        sx={{
          backgroundImage: `url(${Svg})`,
          backgroundSize: { xs: "0%", lg: "35%" },
          backgroundPosition: { lg: "bottom -200px left 100px" },
          backgroundRepeat: "no-repeat",
        }}
      >
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 1,
              flex: 1,
              flexShrink: 0,
            }}
          >
            <Typography sx={{ display: "flex", gap: 1 }}>
              <LocationOnOutlined /> ALAN FZE Office no-E2-110G-04, Hamriyah
              Free Zone Sharjah, United Arab Emirates
            </Typography>
            <Typography sx={{ display: "flex", gap: 1 }}>
              <NumbersOutlined /> Identification number: 14158
            </Typography>
            <Typography sx={{ display: "flex", gap: 1 }}>
              <PhoneInTalkTwoTone />
              <Link href="tel:+971527062372">+971527062372</Link>
            </Typography>
            <Typography sx={{ display: "flex", gap: 1 }}>
              <MailOutline />
              <Link href="mailto:info@alanconsultinguae.com">
                info@alanconsultinguae.com
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: 500,
              p: 3,
              border: "1px solid #ddd",
              borderRadius: 2,
              boxShadow: 2,
              flex: 1,
              flexShrink: 0,
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Contact Us
            </Typography>
            {success !== true && (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                  inputProps={{ autoComplete: "off" }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                  inputProps={{ autoComplete: "off" }}
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                  inputProps={{ autoComplete: "off" }}
                />
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </form>
            )}
            {success === true && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Message sent successfully!
              </Alert>
            )}
            {success === false && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Failed to send the message. Please try again.
              </Alert>
            )}
          </Box>
        </Box>
      </ContentSection>
      </Box>
    </>
  );
}

export default ContactPage;
